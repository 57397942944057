body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  /* https://stackoverflow.com/questions/28217486/font-renders-thicker-in-browsers */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* Reference: https://stackoverflow.com/questions/8919682/remove-all-styling-formatting-from-hyperlinks */
a {
  color: inherit;
  text-decoration: inherit;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

/* Reference: https://stackoverflow.com/questions/1895476/how-to-style-a-select-dropdown-with-css-only-without-javascript */
select,
textarea,
input[type='email'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* remove default arrow */
}

select::-ms-expand {
  display: none; /* hide the default arrow in ie10 and ie11 */
}

@media screen and (min-width: 0\0) {
  select {
    background-image: none\9;
    padding: 5px\9;
  }
}

/* Reference: https://stackoverflow.com/questions/6557784/persistent-margin-and-1px-height-difference-around-input-elements */
span,
input {
  margin: 0;
  padding: 0;
  border-radius: 0;
}

/* Reference: https://codepen.io/benrugg/pen/eKHkL */
@-webkit-keyframes spinnerRotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinnerRotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.bitwise-spin {
  -webkit-animation: spinnerRotate 1200ms infinite linear;
  animation: spinnerRotate 1200ms infinite linear;
}

.ReactModal__Overlay--after-open {
  overflow: hidden;
}

/* https://stackoverflow.com/questions/5345897/html-select-font-size/11869370 */
input[type='submit'] {
  -webkit-appearance: none;
}

/* https://stackoverflow.com/questions/28279989/multiple-font-weights-one-font-face-query */
@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('./fonts/36E285_D_0.eot');
  src: url('./fonts/36E285_D_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/36E285_D_0.woff2') format('woff2'),
    url('./fonts/36E285_D_0.woff') format('woff'),
    url('./fonts/36E285_D_0.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('./fonts/36E285_A_0.eot');
  src: url('./fonts/36E285_A_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/36E285_A_0.woff2') format('woff2'),
    url('./fonts/36E285_A_0.woff') format('woff'),
    url('./fonts/36E285_A_0.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('./fonts/36E285_3_0.eot');
  src: url('./fonts/36E285_3_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/36E285_3_0.woff2') format('woff2'),
    url('./fonts/36E285_3_0.woff') format('woff'),
    url('./fonts/36E285_3_0.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('./fonts/36E285_7_0.eot');
  src: url('./fonts/36E285_7_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/36E285_7_0.woff2') format('woff2'),
    url('./fonts/36E285_7_0.woff') format('woff'),
    url('./fonts/36E285_7_0.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasUnicaPro';
  src: url('./fonts/36E285_0_0.eot');
  src: url('./fonts/36E285_0_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/36E285_0_0.woff2') format('woff2'),
    url('./fonts/36E285_0_0.woff') format('woff'),
    url('./fonts/36E285_0_0.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: 
    url('./fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: 
    url('./fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'Graphik';
  src: 
    url('./fonts/Graphik-Semibold-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Graphik';
  src: 
    url('./fonts/Graphik-Bold-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Bold-Web.woff') format('woff');
  font-weight: 700;
}

@import url("//hello.myfonts.net/count/375f59");
  
@font-face {font-family: 'Tara-Regular';src: url('./fonts/375F59_0_0.eot');src: url('./fonts/375F59_0_0.eot?#iefix') format('embedded-opentype'),url('./fonts/375F59_0_0.woff2') format('woff2'),url('./fonts/375F59_0_0.woff') format('woff'),url('./fonts/375F59_0_0.ttf') format('truetype');}
  
@font-face {font-family: 'Tara-Medium';src: url('./fonts/375F59_1_0.eot');src: url('./fonts/375F59_1_0.eot?#iefix') format('embedded-opentype'),url('./fonts/375F59_1_0.woff2') format('woff2'),url('./fonts/375F59_1_0.woff') format('woff'),url('./fonts/375F59_1_0.ttf') format('truetype');}
 
